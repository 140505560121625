@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 400;
  color: #000;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1000px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1000px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
  width: 100%;
}

#header {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background: #fff;
}

#header .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

@media only screen and (max-width: 1200px) {
  #header .inner {
    padding: 15px 10px;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner {
    padding: 20px 10px;
    display: block;
  }
}

#header .inner .flxL {
  width: 25%;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxL {
    width: 50%;
    margin: 0 auto 0;
  }
}

#header .inner .flxL #siteID a {
  text-align: left;
  display: block;
}

#header .inner .flxL #siteID img {
  width: 100% !important;
  max-width: calc(580px / 2);
}

@media only screen and (max-width: 834px) {
  #header .inner .flxL #siteID img {
    max-width: 100%;
  }
}

#header .inner .flxR {
  width: 70%;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR {
    width: 100%;
  }
}

#header .inner .flxR .subnav {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR .subnav {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 13;
  }
}

#header .inner .flxR .subnav .left .tel2 {
  margin-right: 10px;
  color: #fff;
  background: #1b4996;
  letter-spacing: 0.1em;
  padding: 8px 18px;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR .subnav .left .tel2 {
    padding: 0;
  }
}

#header .inner .flxR .subnav .left .tel2 a {
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 18px;
}

#header .inner .flxR .subnav .left .tel2 i {
  font-size: 20px;
  font-weight: 300;
  margin-right: 7px;
}

#header .inner .flxR .subnav .left .tel2 span {
  font-size: 16px;
}

#header .inner .flxR .subnav .right .cont {
  color: #fff;
  background: #1b4996;
  letter-spacing: 0.1em;
  transition: 1s;
}

#header .inner .flxR .subnav .right .cont:hover {
  background: #2a5cae;
}

#header .inner .flxR .subnav .right .cont a {
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 25px;
}

#header .inner .flxR .subnav .right .cont i {
  font-size: 20px;
  font-weight: 300;
  margin-right: 7px;
}

#header .inner .flxR .subnav .right .cont span {
  font-size: 16px;
}

#nav_global {
  z-index: 10;
  position: relative;
}

#nav_global ul {
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  position: relative;
  flex-grow: 1;
}

#nav_global ul li a {
  line-height: 1;
  display: block;
  color: #333 !important;
  padding: 0 10px;
  font-size: 16px;
  font-size: 1vw;
}

@media only screen and (min-width: 1600px) {
  #nav_global ul li a {
    font-size: 16px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 15px;
    top: 15px;
    color: #333;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #fff;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info span {
    line-height: 1;
    display: block;
    margin-top: 10px;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: #1b4996;
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    background: #376eca;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 70px;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 0.8rem !important;
  }
  #hednav .wrap .navwrap #nav_global ul li a::before {
    display: block;
    position: absolute;
    left: 15px;
    z-index: 1;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 pro";
    color: #1b4996;
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f015";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(2) a::before {
    left: 13px;
    content: "\f085";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(3) a::before {
    left: 19px;
    content: "\f002";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(4) a::before {
    left: 20px;
    content: "\f1fe";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(5) a::before {
    left: 23px;
    content: "\f128";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(6) a::before {
    left: 17px;
    content: "\f2b5";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  position: relative;
  z-index: 0;
  width: 100%;
}

#mainArea .maintxt {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainArea .maintxt h1 {
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-size: 70px;
  font-size: 4.375vw;
}

@media only screen and (min-width: 1600px) {
  #mainArea .maintxt h1 {
    font-size: 70px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #mainArea .maintxt h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .maintxt h1 {
    font-size: 35px;
  }
}

#mainArea .maintxt h1 span {
  display: block;
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main_5.jpg);
  background-position: center;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  z-index: 1;
  position: relative;
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-size: 70px;
  font-size: 4.375vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 {
    font-size: 70px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 35px;
  }
}

#local-keyvisual h1 span {
  background-position: center;
  background-size: cover;
  background-image: url(../images/common/bg.jpg);
  padding: 200px 0;
  display: block;
}

@media only screen and (max-width: 1000px) {
  #local-keyvisual h1 span {
    padding: 100px 10px;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-image: url(../images/common/bg.jpg);
}

.pan1 {
  margin: 50px 0;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #fff;
}

footer#global_footer a {
  color: #fff;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer2 {
  border-bottom: 2px solid #2b5dcd;
  background: url(../images/common/bg03.jpg) center top no-repeat;
  background-size: cover;
  padding: 100px 10px;
}

@media only screen and (max-width: 1000px) {
  #footer2 {
    padding: 50px 10px;
  }
}

#footer2 .inner2 {
  background: rgba(255, 255, 255, 0.7);
  padding: 70px;
}

@media only screen and (max-width: 1000px) {
  #footer2 .inner2 {
    padding: 50px;
  }
}

#footer2 .inner2 dl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

#footer2 .inner2 dl dt {
  margin-bottom: 50px;
  line-height: 1.7em;
  display: block;
  width: 100%;
  font-weight: 700;
  color: #1b4996;
  font-size: 60px;
  font-size: 3.75vw;
}

@media only screen and (min-width: 1600px) {
  #footer2 .inner2 dl dt {
    font-size: 60px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer2 .inner2 dl dt {
    font-size: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 .inner2 dl dt {
    font-size: 40px;
  }
}

#footer2 .inner2 dl dd {
  width: 48%;
}

#footer2 .inner2 dl dd a {
  display: block;
  opacity: 1 !important;
}

#footer2 .inner2 dl dd a:hover {
  opacity: 0.7 !important;
}

#footer2 .inner2 dl dd a img {
  opacity: 1 !important;
}

#footer {
  margin-top: 1px;
  position: relative;
  background: #1b4996;
  padding: 50px 0;
}

@media only screen and (max-width: 1000px) {
  #footer {
    padding: 50px 10px;
  }
}

#footer .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #footer .inner {
    flex-direction: column;
    justify-content: center;
  }
}

#footer .inner .flxL {
  text-align: left;
  width: 48%;
}

@media only screen and (max-width: 834px) {
  #footer .inner .flxL {
    width: auto;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxL {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
}

#footer .inner .flxL #siteID {
  margin-bottom: 30px;
}

#footer .inner .flxL #siteID img {
  opacity: 1 !important;
  width: 100% !important;
  max-width: calc( 580px / 2);
}

#footer .inner .flxR {
  width: 48%;
  text-align: left;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 834px) {
  #footer .inner .flxR {
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxR {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxR #nav_footer {
    display: none;
  }
}

#footer .inner .flxR .map2 {
  margin-right: 50px;
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxR .map2 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner .flxR .map2 iframe {
    width: 100% !important;
    height: 200px !important;
  }
}

#copy {
  background: #1b4996;
  margin-top: 1px;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  display: block;
  line-height: 1.7em;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

.cont_1 {
  background-image: url(../images/common/bg02.png), url(../images/common/bg.jpg);
  background-repeat: no-repeat,no-repeat;
  background-position: center top,center,top;
  background-size: cover;
  padding: 100px 10px;
  margin-bottom: 150px;
}

@media only screen and (max-width: 1000px) {
  .cont_1 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 {
    background-image: url(../images/common/bg02.png), url(../images/common/bg.jpg);
  }
}

.cont_1 .txt {
  padding: 0 150px;
  font-size: 20px;
}

@media only screen and (max-width: 1000px) {
  .cont_1 .txt {
    padding: 0 20%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 .txt {
    padding: 0 10%;
  }
}

.cont_1 .txt > div {
  font-weight: 700;
}

.cont_2wrap {
  max-width: 1200px;
  margin: 0 auto 150px;
}

@media only screen and (max-width: 1200px) {
  .cont_2wrap {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_2wrap {
    margin: 0 auto 80px;
  }
}

.cont_2wrap .cont_2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 640px) {
  .cont_2wrap .cont_2 {
    display: block;
  }
}

.cont_2wrap .cont_2:nth-child(even) {
  flex-direction: row-reverse;
  margin-top: 50px;
}

@media only screen and (max-width: 640px) {
  .cont_2wrap .cont_2:nth-child(even) {
    flex-direction: column;
  }
}

.cont_2wrap .cont_2:nth-child(even) .flxR {
  width: 45%;
  margin-left: 0;
  margin-right: 5%;
}

@media only screen and (max-width: 640px) {
  .cont_2wrap .cont_2:nth-child(even) .flxR {
    width: 100%;
    margin-right: 0;
  }
}

.cont_2wrap .cont_2 .flxL {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .cont_2wrap .cont_2 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_2wrap .cont_2 .flxR {
  width: 45%;
  margin-left: 5%;
}

@media only screen and (max-width: 640px) {
  .cont_2wrap .cont_2 .flxR {
    width: 100%;
    margin-left: 0;
  }
}

.cont_3wrap {
  padding: 100px 10px;
  background: #f0f4fa;
  margin-bottom: 150px;
}

@media only screen and (max-width: 1000px) {
  .cont_3wrap {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3wrap {
    padding: 50px 10px;
  }
}

.cont_3wrap .cont_3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .cont_3wrap .cont_3 {
    display: block;
  }
}

.cont_3wrap .cont_3 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_3wrap .cont_3 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_3wrap .cont_3 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_3wrap .cont_3 .flxR {
    width: 100%;
  }
}

.news {
  background: #fff;
  padding: 20px;
}

.news .blog_list {
  padding-right: 10px;
  height: 350px;
  overflow-y: scroll;
  border: 0;
  display: block !important;
}

.news .blog_list > div {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
}

.news .blog_list > div:first-child {
  padding: 0 0 15px;
}

.news .blog_list > div:last-child {
  border-bottom: 1px dashed #333;
}

.news .blog_list > div .blog_photo {
  display: none;
}

.news .blog_list > div .blog_text {
  padding: 0;
  font-size: 16px;
  width: 100%;
  display: block;
}

.news .blog_list > div .blog_text .blog_date {
  color: #1b4996;
  font-weight: 700;
}

.news .blog_list > div .blog_text h3 a {
  color: #333;
}

.news .blog_list > div .blog_text .detail, .news .blog_list > div .blog_text ul {
  display: none;
}

.media {
  max-width: 1200px;
  margin: 0 auto 120px;
}

@media only screen and (max-width: 1200px) {
  .media {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .media {
    margin: 0 auto 80px;
  }
}

.media .sns_list {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1.63934%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media .sns_list {
    margin-left: -1.63934%;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list {
    justify-content: space-between;
    margin-left: 0;
  }
}

.media .sns_list > div {
  border-bottom: 0;
  width: 18.36066%;
  height: 18.36066%;
  margin-left: 1.63934%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media .sns_list > div {
    width: 18.36066%;
    margin-left: 1.63934%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list > div {
    width: 48%;
    margin-left: 0;
  }
}

.media .sns_list > div .sns_photo {
  width: 100%;
  padding: 0;
}

.media .sns_list > div .sns_photo a {
  display: block;
}

.media .sns_list > div .sns_photo img {
  height: 11.6666vw !important;
  width: 100% !important;
  object-fit: cover;
  object-position: 0 100%;
  display: block;
  font-family: 'object-fit: cover;';
}

@media only screen and (min-width: 1600px) {
  .media .sns_list > div .sns_photo img {
    height: 223.94px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .media .sns_list > div .sns_photo img {
    height: 20vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list > div .sns_photo img {
    height: 40vw !important;
  }
}

.media .sns_list > div .sns_text {
  display: none;
}

/* title */
.midashi {
  display: block;
  width: 100%;
}

.midashi h2 {
  line-height: 1;
  color: #1b4996;
  font-weight: 700;
  font-size: 60px;
  font-size: 3.75vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 60px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 40px;
  }
}

.midashi h2 span {
  margin-top: 30px;
  font-weight: 500;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
  color: #000;
  font-size: 45px;
  font-size: 2.8125vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 span {
    font-size: 45px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi h2 span {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 span {
    font-size: 25px;
  }
}

.midashi2 h2 {
  color: #000;
  font-weight: 700;
  font-size: 35px;
  font-size: 2.1875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 35px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h2 {
    font-size: 20px;
  }
}

.midashi2 h3 {
  color: #000;
  font-weight: 700;
  font-size: 35px;
  font-size: 2.1875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h3 {
    font-size: 35px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi2 h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h3 {
    font-size: 20px;
  }
}

.midashi3 h2 {
  color: #000;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

.midashi3 h3 {
  color: #000;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h3 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi3 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h3 {
    font-size: 20px;
  }
}

.midashi4 h2 {
  letter-spacing: 0.2em;
  padding: 20px 15px;
  font-weight: 700;
  color: #333;
  background: #f4f4f4;
  border-left: solid 5px #1b4996;
  border-bottom: solid 3px #d7d7d7;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h2 {
    font-size: 18px;
  }
}

.midashi4 h3 {
  letter-spacing: 0.2em;
  padding: 20px 15px;
  font-weight: 700;
  color: #333;
  background: #f4f4f4;
  border-left: solid 5px #1b4996;
  border-bottom: solid 3px #d7d7d7;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h3 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi4 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h3 {
    font-size: 18px;
  }
}

.cont_4wrap {
  max-width: 1200px;
  margin: 0 auto 50px;
}

@media only screen and (max-width: 1200px) {
  .cont_4wrap {
    padding: 0 10px;
  }
}

.cont_4wrap .cont_4:nth-child(even) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .cont_4wrap .cont_4:nth-child(even) {
    flex-direction: column;
  }
}

.cont_4wrap .cont_4:nth-child(even) .flxR {
  width: 45%;
  margin-left: 0;
  margin-right: 5%;
}

@media only screen and (max-width: 640px) {
  .cont_4wrap .cont_4:nth-child(even) .flxR {
    width: 100%;
    margin-right: 0;
  }
}

.cont_4 {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 640px) {
  .cont_4 {
    display: block;
  }
}

.cont_4 .flxL {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .cont_4 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_4 .flxR {
  width: 65%;
  margin-left: 5%;
}

@media only screen and (max-width: 640px) {
  .cont_4 .flxR {
    width: 100%;
    margin-left: 0;
  }
}

.cont_5wrap {
  max-width: 1200px;
  margin: 0 auto 50px;
}

@media only screen and (max-width: 1200px) {
  .cont_5wrap {
    padding: 0 10px;
  }
}

.cont_5wrap .cont_5 {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 640px) {
  .cont_5wrap .cont_5 {
    display: block;
  }
}

.cont_5wrap .cont_5:nth-child(even) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .cont_5wrap .cont_5:nth-child(even) {
    flex-direction: column;
  }
}

.cont_5wrap .cont_5:nth-child(even) .flxR {
  width: 45%;
  margin-left: 0;
  margin-right: 5%;
}

@media only screen and (max-width: 640px) {
  .cont_5wrap .cont_5:nth-child(even) .flxR {
    width: 100%;
    margin-right: 0;
  }
}

.cont_5wrap .cont_5 .flxL {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .cont_5wrap .cont_5 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_5wrap .cont_5 .flxR {
  width: 45%;
  margin-left: 5%;
}

@media only screen and (max-width: 640px) {
  .cont_5wrap .cont_5 .flxR {
    width: 100%;
    margin-left: 0;
  }
}

.faq_q {
  border: none !important;
}

.faq_q > div {
  font-size: 16px;
  border-bottom: 1px #1b4996 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #1b4996;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq_q > div:before {
  content: "Q";
  display: block;
  background: #1b4996;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -3px;
}

.faq_a {
  border: none !important;
}

.faq_a > div {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #376eca solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq_a > div:before {
  content: "A";
  display: block;
  background: #376eca;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -6px;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.waku {
  padding: 40px;
  background: whitesmoke;
  border: double 7px #1b4996;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.comp dl {
  border-bottom: 1px solid #ccc;
}

.comp dl:nth-child(1) {
  border-top: 1px solid #ccc;
}

.comp dl dt {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl dd {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl a {
  color: #b2b2b2;
}

@media only screen and (max-width: 640px) {
  .comp2 dl {
    text-align: center;
    display: block !important;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dt {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
}

.comp2 dl dd {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dd {
    width: 100% !important;
  }
}

.comp2 dl a {
  color: #b2b2b2;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}
